import * as React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `rgb(45, 102, 193)`,
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.45rem 1.0875rem`,
        display: "flex",
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <div style={{ flex: 1 }} />
      <h3 style={{ margin: 0, marginLeft: 12, marginRight: 6, marginTop: 8 }}>
        <Link
          to="/resume/"
          style={{
            color: "white",
            textDecoration: "none",
          }}
        >
          Resume
        </Link>
      </h3>
      <h3 style={{ margin: 0, marginLeft: 12, marginRight: 6, marginTop: 8 }}>
        <Link
          to="/portfolio/"
          style={{
            color: "white",
            textDecoration: "none",
          }}
        >
          Portfolio
        </Link>
      </h3>
      <h3 style={{ margin: 0, marginLeft: 12, marginRight: 6, marginTop: 8 }}>
        <Link
          to="/code/"
          style={{
            color: "white",
            textDecoration: "none",
          }}
        >
          Code Blog
        </Link>
      </h3>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
